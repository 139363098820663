import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import DataService from '../services/data'

const useGetStats = () => {

    const [state] = useContext(AppContext)

    const [stats, setStats] = useState({ average: 0, max: 0, min: 0 });
    const [isLoadingStats, setIsLoading] = useState(true)
    const [isErrorStats, setIsError] = useState(false)

    useEffect(() => {
        // get data for line charts
        const getStats = async () => {
            try {
                setIsLoading(true)
                const response = await DataService.getStats(
                    state.activeParam?.param,
                    state.activeYear.chartVar,
                    state.activeRcp.chartVar,
                    state.activeSeason.chartVar
                );
                let stats = response.data;
                setStats(stats);
                setIsLoading(false)
            } catch (err) {
                console.log('get stats error', err)
                setIsError(true)
                setIsLoading(false)
            }
        }
        getStats()
    }, [
        state.activeParam,
        state.activeYear,
        state.activeRcp,
        state.activeSeason])

    return { isLoadingStats, isErrorStats, stats }
}
export default useGetStats