import React from "react";
import "./ToolBottom.css";
import {dataSources} from '../../data/dataSources'
import logo1 from "../../components/ACIS-2014.png";



const ToolBottom = ({ logo=logo1, about }) => {
// console.log(about.variables)
  return (
    <>
      {/* <h2>About the data</h2> */}
      <p>{about?.desc}</p>
      {about?.variables.map((item, index) => (
        <div key={index}>
          <h3>{item.title}</h3>
          {item.desc.map(para => (
            <p>{para}</p>
          ))}
          
        </div>
      ))}

      <h2>Data Sources</h2>
      {dataSources.map((item, index) => (
        <div key={index}>
          <h3>
            <a href={item.link}>
              <img src={logo} alt="data source logo" style={{maxWidth: '300px'}} />
              {item.title}
            </a>
          </h3>
          <p>{item.desc}</p>
        </div>
      ))}
    </>
  );
};

export default ToolBottom;
