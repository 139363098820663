exports.aboutRcps = {
  desc: "RCPs or Representative Concentration Pathways (RCPs) are narrative descriptions about how global greenhouse gas emissions (such as carbon dioxide and methane), air pollutants, and land use may change over the 21st century. These narratives are guided by expert analysis of current emission trends and changes over the next century due to economic and industrial growth, adoption of alternative energy sources (such as wind and solar), and societal shifts. The two RCP scenarios presented here are RCP 4.5 (moderate) and RCP 8.5 (high), which represent an intermediate and high emission scenario, respectively.",
  variables: [
    {
      title: "RCP 4.5 (moderate)",
      desc: "In RCP 4.5,global greenhouse gas emissions peak near 2035-2040 and are reduced substantially by 2080-2100. This reduction is mainly dependent on changes in energy generation, an increase in energy efficiencies, and a decrease in overall energy usage. Of the energy that is used, the proportion of energy generated from fossil fuels decline and other sources (such as renewables and nuclear energy) increase.  By 2100, the global surface temperatures increase by 1.8 (1.1-2.6 likely range) degrees C relative to 1986-2005.",
    },
    {
      title: "RCP 8.5 (high)",
      desc: "RCP 8.5 assumes continued and expanding greenhouse gas emissions throughout the century without any significant emissions reduction or climate policy. The scenario depicts a rapidly growing global population with slow economic development that results in high energy demand with little progress in energy efficiency and alternative energy sources. To meet this demand, traditional fossil fuel (such as coal) usage increases. By 2100, the global surface temperatures increase by 3.7 (2.6-4.8 likely range) degrees C relative to 1986-2005.",
    },
  ],
}