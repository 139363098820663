// inspired by https://www.positronx.io/react-file-upload-tutorial-with-node-express-and-multer/

import http from "../http-common";


class DataService {

    getData(param, period, scenario, season) {
        // console.log(`getting data`)
        return http.get(`/api/data/${param}/${period}/${scenario}/${season}`)
    }
    getStats(param, period, scenario, season) {
        return http.get(`api/data/stats/${param}/${period}/${scenario}/${season}`)
    }
    getHistorical(param,season) {
        return http.get(`/api/historical/${param}/${season}`)
    }

}

export default new DataService ();
