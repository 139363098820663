// libraries
import React, { useContext } from "react";
import styled from "styled-components";
// css
import "./pages.css";
// tools
import ToolHeader from "../ToolHeader";
import Modal from "../Modal";
import aboutRCPs from "../aboutRcps/aboutRCPs";
import TopicBar from "../TopicBar";
import AppContext from "../../AppContext";
import YearsRadios from "../YearsRadios";
import RcpRadios from "../RcpRadios";
import SeasonRadios from "../SeasonRadios";
import ChartSection from "../ChartSection/ChartSection";
import StatsSection from "../StatsSection/StatsSection";
import MapSection from "../MapSection/MapSection";
import GeneralStyles from "../../GeneralStyles";
import ToolBottom from "../ToolBottom/ToolBottom";
 //todo reactivate ChooseVis when maps work again 
// import ChooseVis from "../ChooseVis";

let height = window.innerHeight;

const Page = () => {

  const [state] = useContext(AppContext)

  return (
    <GeneralStyles.PageCont>
      <TopicBar />
      <ToolHeader />
      <Styles.LowerCont>
        <Styles.FlexCol>
          {/* //todo reactivate ChooseVis when maps work again */}
           {/* <ChooseVis />  */}
          {/* select years */}
          <SelectionBlock>
            <span>Select Time Period</span>
            <YearsRadios />
          </SelectionBlock>
          {/* select emissions */}
          <SelectionBlock>
            <span>Select Emissions Scenario</span>
            <RcpRadios />
            {height >= 700 && (
              <p style={{ padding: "1rem" }}>
                Learn More{" "}
                <Modal info={<ToolBottom about={aboutRCPs}/>} what="Climate Scenarios" />
              </p>
            )}
          </SelectionBlock>
          {/* select season */}
          <SelectionBlock>
            <span>Select Season</span>
            <SeasonRadios />
          </SelectionBlock>
        </Styles.FlexCol>
        {state.activeViz === "Map"
          ? (
            <MapSection />
          )
          : (
            <Styles.ChartViz id="chart-viz">
              <Styles.ChartWrapper id="chart-wrapper">
                <StatsSection />
                <ChartSection />
              </Styles.ChartWrapper>
            </Styles.ChartViz>
          )}
      </Styles.LowerCont>
    </GeneralStyles.PageCont>
  );
};

export default Page;



const Section = styled.div`
          display: flex;
          justify-content: center;
          align-items: center;
          `;
const SelectionBlock = styled.div`
          background: #f1f1f1;
          width: 100%;
          margin-top: 1rem;
          padding: 2rem;
          span {
            color: #00626d;
          text-transform: uppercase;
          font-weight: bold;
  }
          `;

const Styles = {


  FlexCol: styled(Section)`
          flex-direction: column;
          `,
  ChooseViz: styled(SelectionBlock)`
          margin-top: 3rem;
          `,


  ChartViz: styled(Section)`
          flex-direction: column;
          width: 66vw;
          margin: 3rem;
          `,
  ChartWrapper: styled.div`
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          `,
  VizStats: styled(Section)`
          margin-bottom: 2rem;
          `,


  LowerCont: styled.div`
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          @media screen and (max-width: ${({ theme }) => theme.mobile}) {
            display: flex;
          justify-content: center;
          flex-wrap: wrap;
          flex-direction: row-reverse; /* <--- Really freaking cool! */
      // align-items: center;
    }
          `,
};
