import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import DataService from '../services/data'

const useGetHistorical = () => {

    const [state] = useContext(AppContext)

    const [historical, setHistorical] = useState([]);
    const [isLoadingHist, setIsLoading] = useState(true)
    const [isErrorHist, setIsError] = useState(false)

    useEffect(() => {
        // get data for line charts
        const getHist = async () => {
            try {
                setIsLoading(true)
                const response = await DataService.getHistorical(
                    state.activeParam?.param,
                    state.activeSeason.chartVar
                );
                let { averages } = response.data;
                setHistorical(averages);
                setIsLoading(false)
            } catch (err) {
                console.log('get historical error', err)
                setIsError(true)
                setIsLoading(false)
            }
        }
        getHist()
    }, [
        state.activeParam,
        state.activeSeason])

    return { isLoadingHist, isErrorHist, historical }
}
export default useGetHistorical