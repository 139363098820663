import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import DataService from '../services/data'

const useGetData = () => {

    const [state] = useContext(AppContext)

    const [data, setData] = useState({
        averages: [],
        ranges: [],
    });
    const [isLoadingData, setIsLoading] = useState(true)
    const [isErrorData, setIsError] = useState(false)

    useEffect(() => {
        // get data for line charts
        const getData = async () => {
            try {
                setIsLoading(true)
                const response = await DataService.getData(
                    state.activeParam?.param,
                    state.activeYear.chartVar,
                    state.activeRcp.chartVar,
                    state.activeSeason.chartVar
                );
                // console.log('response', response)
                let data = response.data;
                // setData(data);
                // setIsLoading(false)
                // setActiveSeason(initialSeason)
                return data

            } catch (err) {
                console.error(err)
                console.log('get Data error', err)
                // setIsError(true)
                // setIsLoading(false)
            }
        }
        getData().then(data => {
            setData(data);
            setIsLoading(false)
        }).catch(err => {
            console.error(err)
            console.log('get Data error', err)
            setIsError(true)
            setIsLoading(false)

        }
        )

    }, [
        state.activeParam,
        state.activeYear,
        state.activeRcp,
        state.activeSeason])

    return { isLoadingData, isErrorData, data }
}
export default useGetData