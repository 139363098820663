import { useContext } from "react"
import Stats from "./Stats"
import AppContext from "../../AppContext"
import useGetStats from "../../tools/useGetStats"

const StatsSection = () => {
    const [state] = useContext(AppContext)
    const { isLoadingStats, isErrorStats, stats } = useGetStats()
    return isLoadingStats
        ? <h2>Gathering fresh data...</h2>
        : isErrorStats
            ? <h2>Something has gone wrong while gathering data for stats. Hit refresh to try again.</h2>
            : <Stats
                color={state.activeColors}
                title={`${state.activeParam?.statsTitle} for ${state.activeYear.year} ${state.activeParam?.statsUnit}`}
                values={stats}
            />

}
export default StatsSection